<template>
  <div class="pie-chart pt-50">
    <div ref="pieChart" class="pie-chart-bar"></div>
  </div>
</template>
<script>
export default {
  props: ['title', 'data', 'legendShow'],
  mounted () {
    this.initEcharts()
  },
  methods: {
    initEcharts () {
      let myChart = this.$echarts.init(this.$refs.pieChart)
      let option = {
        title: {
          text: this.title,
          left: 'center',
        },
        tooltip: {
          trigger: 'item',
        },
        legend: {
          show: this.legendShow, // 控制图例显示隐藏
          left: 'center',
          bottom: '0',
          textStyle: { //图例文字的样式
            fontSize: 14
          },
        },
        series: [
          {
            type: 'pie',
            selectedMode: 'single',
            radius: '70%',
            top: '30',
            center: ['center', '45%'],
            label: {
              // position: 'inner',
              formatter: this.legendShow ? '{d}%' : '{b}：{d}%',
              // fontSize: 16,
              // fontWeight: 'bold',
              // color: "#fff"
            },
            itemStyle: {
              borderWidth: 3, // 间距的宽度
              borderColor: '#fff', //背景色
            },
            data: this.data
          },
        ]
      }
      option && myChart.setOption(option);
    },
  }
}
</script>

<style lang="scss" scoped>
.pie-chart {
  .pie-chart-bar {
    width: 500px;
    height: 360px;
  }
}
</style>