/** 企业详情页 */
<template>
  <div v-if="epDetailsData" class="enterprise-details">
    <section class="enterprise-info back-fff mb-10">
      <div class="content">
        <div class="justify-between-center">
          <div class="details-name">
            <h4 class="mb-10">{{ epDetailsData.company_info.name }}</h4>
            <div class="enterprise-tag">
              <template v-for="item in epDetailsData.company_info.tags.split(';')">
                <el-tag v-if="item != '曾用名'" class="mr-8 mb-10" :key="item" color="#6AA2FF" effect="dark" size="small ">
                  {{ item }}
                </el-tag>
                <!-- 曾用名 -->
                <el-dropdown :key="item" v-if="
                    item == '曾用名' &&
                    epDetailsData.company_info.historyNameList.length
                  " trigger="click">
                  <el-tag class="mr-8 el-dropdown-link" color="#6AA2FF" effect="dark" size="small ">
                    曾用名>
                  </el-tag>
                  <el-dropdown-menu slot="dropdown" class="user-info-dropdown">
                    <el-dropdown-item v-for="(item, index) in epDetailsData.company_info
                        .historyNameList" :key="index">
                      {{ item }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </div>
          </div>
          <div class="details-btns">
            <el-button class="monitor-btn" :loading="monitor_load" @click="monitorBtn(epDetailsData.company_info.id)">
              {{
                !$store.getters.userToken ||
                !epDetailsData.company_info.associate_company_status
                  ? "监控"
                  : "取消监控"
              }}
            </el-button>
            <el-button class="report-download theme-back ml-20" @click="$store.dispatch('setDownloadDialog', true)">报告下载
            </el-button>
          </div>
        </div>
        <ul class="details-text pt-32 flex-wrap">
          <li v-if="epDetailsData.company_info.legalPersonName">
            法人代表：{{ epDetailsData.company_info.legalPersonName }}
          </li>
          <li v-if="epDetailsData.company_info.phoneNumber">
            电话：{{ epDetailsData.company_info.phoneNumber }}
          </li>
          <li v-if="epDetailsData.company_info.email">
            邮箱：{{ epDetailsData.company_info.email }}
          </li>
          <li v-if="epDetailsData.company_info.websiteList">
            官网：{{ epDetailsData.company_info.websiteList }}
          </li>
        </ul>
      </div>
    </section>
    <section>
      <el-tabs class="ep-tabs" v-model="tabsName" type="card" @tab-click="handleTabs">
        <el-tab-pane label="企业信息" name="info" :lazy="true">
          <Info :epDetailsData="epDetailsData" :epShareholders="epShareholders" />
        </el-tab-pane>
        <el-tab-pane label="专利资产" name="patent" :lazy="true">
          <EpPatent :company_info="epDetailsData.company_info" />
        </el-tab-pane>
        <el-tab-pane label="商标资产" name="trademark" :lazy="true">
          <EpTrademark :company_info="epDetailsData.company_info" />
        </el-tab-pane>
      </el-tabs>
    </section>
    <!-- 下载框 -->
    <AssetsDownload downloadType="company" :downloadVal="epDetailsData.company_info.name" />
  </div>
  <el-empty v-else :image-size="200"></el-empty>
</template>

<script>
import Info from "./info.vue";
import EpPatent from "./epPatent.vue";
import EpTrademark from "./epTrademark.vue";
import AssetsDownload from "@/components/Assets/download.vue";
import { setItem, getItem } from "@/utils/storage";
import { getEpDetails, getEpShareholders, setEpMonitor } from "@/api/enterprise";
export default {
  name: "EnterpriseDetails",
  components: {
    Info,
    EpPatent,
    EpTrademark,
    AssetsDownload,
  },
  data () {
    return {
      tabsName: getItem("ep_tabs_name", "sessionStorage") || "info",
      monitor_load: false, // 监控按钮加载状态
      epDetailsData: null,
      epShareholders: {}
    };
  },
  mounted () {
    this.getEpDetails();
  },
  methods: {
    // 获取企业详情信息
    async getEpDetails () {
      let company_name = this.$route.query.qn;
      let qnInd = company_name.lastIndexOf(" ");
      if (qnInd != -1) {
        company_name = company_name.substr(0, qnInd);
      }
      try {
        let { data } = await getEpDetails(company_name);
        data.company_info["my_provinces"] = await this.getCity(data.company_info.base);
        this.epDetailsData = data;
      } catch (error) {
        return false;
      }
    },
    // 获取企业股东信息
    async getEpShareholders (page = 1) {
      let company_name = this.$route.query.qn;
      let qnInd = company_name.lastIndexOf(" ");
      if (qnInd != -1) {
        company_name = company_name.substr(0, qnInd);
      }
      try {
        let { data } = await getEpShareholders({ keywords: company_name, page })
        this.epShareholders = data || {}
      } catch (error) {
        return false
      }
    },
    // 获取省份名称
    async getCity (base) {
      let { data } = await this.$http.get('/city.json')
      let provincesObj = data.find(i => i.base == base) || {}
      return provincesObj.provinces
    },
    // 监控
    async monitorBtn (id) {
      if (!this.$store.getters.userToken)
        return this.$message.warning("请先登录");
      this.monitor_load = true;
      try {
        let { data, status, msg } = await setEpMonitor({ keywords: id, monitor_status: this.epDetailsData.company_info.associate_company_status ? 2 : 1 });
        if (status == 200) {
          this.epDetailsData.company_info.associate_company_status =
            !this.epDetailsData.company_info.associate_company_status;
          this.$message.success(
            this.epDetailsData.company_info.associate_company_status
              ? "监控成功"
              : "取消监控成功"
          );
        } else {
          this.$message.error(
            this.epDetailsData.company_info.associate_company_status
              ? "取消监控失败"
              : "监控失败"
          );
        }
        this.monitor_load = false;
      } catch (error) {
        this.monitor_load = false;
      }
    },
    // tabs切换
    handleTabs (tab) {
      setItem("ep_tabs_name", tab.name, "sessionStorage");
    },
  },
};
</script>

<style lang="scss" scoped>
.enterprise-info {
  padding: 36px 0;
  .details-name {
    width: 76%;
    p {
      font-size: 18px;
      color: #1a1a1a;
    }
  }
  .details-text {
    li {
      margin-right: 38px;
      line-height: 24px;
    }
  }
}
::v-deep .el-tabs.ep-tabs > .el-tabs__header {
  background-color: #fff;
}
</style>