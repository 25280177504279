/** 企业详情中的--专利、商标列表 */
<template>
  <div class="content">
    <el-tabs v-model="assetsTab" type="card" @tab-click="handleTabs">
      <el-tab-pane v-for="(item,index) in assetsTaps" :key="index" :label="item.label"
        :name="typeof item.name == 'string'?item.name:item.name[0]"> </el-tab-pane>
    </el-tabs>
    <div class="justify-between-center assets-filters">
      <ul class="flex">
        <li>
          <el-date-picker v-model="filter1" type="year" placeholder="申请年份" value-format="yyyy"
            :picker-options="pickerOptions"> </el-date-picker>
        </li>
        <li>
          <el-select v-if="assetsType=='patent'" v-model="filter2" multiple collapse-tags placeholder="专利类型">
            <el-option v-for="item in patentType" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-select v-else v-model="filter2" filterable multiple collapse-tags clearable placeholder="国际分类">
            <el-option v-for="item in tradeNc" :key="item.value" :label="item.nc_cn" :value="item.value">
            </el-option>
          </el-select>
        </li>
        <li>
          <el-select v-model="filter3" multiple collapse-tags clearable placeholder="法律状态">
            <el-option v-for="item in legalStatus" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </li>
        <li>
          <el-select v-if="isResidueYear" v-model="filter4" multiple collapse-tags clearable placeholder="剩余年限">
            <el-option v-for="item in residueYear" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </li>
        <li class="ml-10">
          <el-button class="theme-back border-r6" @click="filterBtn()">确定</el-button>
        </li>
      </ul>
      <div class="flex list-btns">
        <el-button :class="{'theme-back':epAssetsSort=='-AD'||epAssetsSort=='-FD'}"
          @click="setSort(assetsType=='patent'?'-AD':'-FD')">申请日
        </el-button>
        <el-button :class="{'theme-back':epAssetsSort=='-PD'||epAssetsSort=='-RD'}"
          @click="setSort(assetsType=='patent'?'-PD':'-RD')">公开日
        </el-button>
      </div>
    </div>
    <AssetsTable v-bind="$attrs" v-on="$listeners" class="mt-32" :isIndex='true'>
      <template v-slot:ano='{scope}'>
        <router-link target="_blank" :to="{path:'/patent-details',query:{pid:scope.row.pid}}">
          <el-button type="text" class="pa-0"> {{scope.row.ano}} </el-button>
          <p>
            <el-tag color="#6AA2FF" effect="dark" size="small ">权利要求数量：{{scope.row.cln || 0}}项</el-tag>
          </p>
        </router-link>
      </template>
      <template v-slot:rn='{scope}'>
        <router-link target="_blank" :to="{path:'/trademark-details',query:{tid:scope.row.tid}}">
          <el-button type="text" class="pa-0">{{scope.row.rn}} </el-button>
        </router-link>
      </template>
    </AssetsTable>
  </div>
</template>
<script>
import AssetsTable from '@/components/Assets/table.vue'
import Page from '@/components/Page.vue'
export default {
  props: {
    assetsType: String,
    assetsTaps: {
      default: []
    },
    assetsLegalStatus: {
      default: []
    },
    tradeNc: {
      default: () => {
        return []
      }
    }
  },
  components: {
    AssetsTable,
    Page
  },
  data () {
    return {
      assetsTab: '',
      epAssetsSort: this.assetsType == 'patent' ? '-AD' : '-FD',//排序,
      filter1: '',// 年
      filter2: '',// 类型
      filter3: '',// 法律状态
      filter4: '',// 剩余年限
      //专利类型
      patentType: [{
        value: '发明',
        label: '发明专利'
      }, {
        value: '实用新型',
        label: '实用新型'
      }, {
        value: '外观设计',
        label: '外观设计'
      }],
      legalStatus: [...this.assetsLegalStatus],// 法律状态
      isResidueYear: false,// 是否展示剩余年限选择框
      // 剩余年限
      residueYear: [
        { value: 8, label: '≥1' },
        { value: 9, label: '≥2' },
        { value: 10, label: '≥3' },
        { value: 11, label: '≥4' },
        { value: 12, label: '≥5' },
      ],
      tabData: {},
      filterSelect: {},
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        }
      }
    }
  },
  mounted () {
    this.assetsTab = this.assetsTaps[0].name
  },
  methods: {
    // tabs筛选
    handleTabs (tab) {
      this.tabData = {}
      let field = this.assetsTaps[tab.index].field
      this.tabData[field] = this.assetsTaps[tab.index].name
      // 商标--选择‘可质押商标’时法律状态改变
      if (this.assetsType == "trademark" && this.tabData.pledge == '2') {
        this.legalStatus.splice(this.legalStatus.length - 2)
      } else {
        this.legalStatus = [...this.assetsLegalStatus]
      }
      // 专利--选择‘可质押专利’时剩余年限显示
      if (this.assetsType == "patent" && this.tabData.pledge == '2') {
        this.isResidueYear = true
      } else {
        this.isResidueYear = false
      }
      this.filterBtn(true)
      this.sortingData()
    },
    // 下拉选择框--确定
    filterBtn (isClearAll) {
      this.filterSelect = {}
      if (isClearAll) {
        this.filter1 = ''
        this.filter2 = ''
        this.filter3 = ''
        this.filter4 = ''
        return false
      }
      if (this.assetsType == 'patent') {
        if (this.filter1) {
          this.filterSelect['AD'] = [this.filter1]
        }
        if (this.filter2.length) {
          this.filterSelect['PDT'] = this.filter2
        }
        if (this.filter3.length) {
          this.filterSelect['LSSCN'] = this.filter3
        }
        if (this.isResidueYear && this.filter4.length) {
          this.filterSelect['RD'] = this.filter4
        }
      } else {
        if (this.filter1) {
          this.filterSelect['FY'] = [this.filter1]
        }
        if (this.filter2.length) {
          this.filterSelect['NC'] = this.filter2
        }
        if (this.filter3.length) {
          this.filterSelect['CS'] = this.filter3
        }
      }

      this.sortingData()
    },
    // 排序
    setSort (sort) {
      this.epAssetsSort = sort
      this.sortingData()
    },
    // 整合数据
    sortingData () {
      let filtersData = { ...this.tabData, ...this.filterSelect }
      filtersData['sort'] = this.epAssetsSort
      filtersData['page'] = 1
      this.$emit('sortingFilters', filtersData)
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .assets-filters {
  ul {
    margin-right: 40px;
    li:nth-child(n + 2) {
      margin-left: 10px;
    }
    .el-date-editor.el-input {
      width: auto;
    }
  }
  .el-input__inner,
  .el-button {
    height: 32px;
    line-height: 0;
  }
  .el-input__icon {
    line-height: 32px;
  }
}

::v-deep .el-table {
  td.el-table__cell {
    .el-tag--dark {
      color: #fff;
      height: 18px;
      line-height: 18px;
    }
  }
}

::v-deep .list-btns {
  .el-button {
    border: 1px solid #8491ae;
    &.theme-back {
      border-color: #2974ef;
    }
  }
}
</style>