/** 企业信息 */
<template>
  <div>
    <!-- 基本信息 -->
    <div class="back-fff mb-10">
      <ul class="assets content justify-center">
        <li class="flex">
          <el-image class="assets-img" :src="require('@/assets/images/patent-icon.png')" fit="contain"> </el-image>
          <div class="assets-text">
            <p class="assets-name mb-10">专利量</p>
            <p> <span class="assets-num">{{epDetailsData.patent_num || 0}}</span> 件 </p>
          </div>
        </li>
        <li class="flex">
          <el-image class="assets-img" :src="require('@/assets/images/trademark-icon.png')" fit="contain"> </el-image>
          <div class="assets-text">
            <p class="assets-name mb-10">商标量</p>
            <p> <span class="assets-num">{{epDetailsData.trade_num || 0}}</span> 件 </p>
          </div>
        </li>
      </ul>
    </div>

    <div class="back-fff ">
      <div class="content pb-50">
        <el-tabs v-model="tabsName" type="card" @tab-click="handleClick">

          <el-tab-pane label="基本信息" name="info">
            <AssetsDescriptions :data="infoData.data" :labelData="infoData.label" />
          </el-tab-pane>

          <el-tab-pane label="股权信息" name="equity">
            <el-tabs v-model="equityTabs" class="tabs-line">
              <el-tab-pane label="股东信息" name="shareholders">
                <AssetsTable :isIndex="true" :isIndexMethod="true" :data="shareholders.data"
                  :labelData="shareholders.label" :page="shareholders.page" :pageSize="20"
                  :total="shareholders.pageTotal" @changePage="changePage" />
              </el-tab-pane>
              <!-- <el-tab-pane label="对外投资" name="investment">
                <AssetsTable :isIndex="true" :data="investment.data" :labelData="investment.label">
                  <template v-slot:name="{scope}">
                    <router-link target="_blank" :to="{path:'/enterprise-details',query:{qn:scope.row.name}}">
                      <el-button type="text" class="pa-0">{{scope.row.name}}</el-button>
                    </router-link>
                  </template>
                </AssetsTable>
              </el-tab-pane> -->
            </el-tabs>
          </el-tab-pane>

          <el-tab-pane label="主要人员" name="mainStaff">
            <AssetsTable :data="mainStaff.data" :labelData="mainStaff.label" />
          </el-tab-pane>

        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import AssetsDescriptions from '@/components/Assets/descriptions.vue'
import AssetsTable from '@/components/Assets/table.vue'
export default {
  props: ['epDetailsData', 'epShareholders'],
  components: {
    AssetsDescriptions,
    AssetsTable
  },
  data () {
    return {
      tabsName: 'info',
      equityTabs: 'shareholders',
      // 基本数据
      infoData: {
        label: [
          { label: '企业名称', value: 'name', },
          { label: '法人代表', value: 'legalPersonName', },
          { label: '成立日期 ', value: 'estiblishTime' },
          { label: '企业状态', value: 'regStatus', },
          { label: '工商注册号', value: 'regNumber', },
          { label: '统一社会信用代码', value: 'creditCode', },
          { label: '登记机关', value: 'regInstitute', },
          { label: '所属地区', value: 'my_provinces', },
          { label: '注册资本', value: 'regCapital', },
          { label: '企业类型', value: 'companyOrgType', },
          { label: '注册地址', value: 'regLocation', },
          { label: '核准日期', value: 'approvedTime' },
          { label: '组织机构代码', value: 'orgNumber', },
          { label: '营业期限', value: 'Businessterm' },
          { label: '参保人数', value: 'socialStaffNum', },
          { label: '曾用名', value: 'historyNameList', },
          { label: '人员规模', value: 'staffNumRange', },
          { label: '行业', value: 'industry', },
          { label: '经营范围', value: 'businessScope', },
        ],
        data: this.epDetailsData.company_info || null
      },
      // 股东信息
      shareholders: {
        label: [
          { label: '股东', value: 'name' },
          { label: '出资比例', value: 'capital', value2: 'percent' },
          { label: '认缴出资额', value: 'capital', value2: 'amomon' },
          { label: '认缴出资日', value: 'capital', value2: 'time' },
        ],
        data: this.epShareholders.items || null,
        page: 1,
        pageTotal: this.epShareholders.total
      },
      // 对外投资
      investment: {
        label: [
          { label: '被投资企业名称', value: 'name' },
          { label: '被投资法定代表人', value: 'capital', value2: 'percent' },
          { label: '投资占比', value: 'capital', value2: 'amomon' },
          { label: '注册资本', value: 'capital', value2: 'time' },
          { label: '成立日期', value: 'capital', value2: 'time' },
          { label: '经营状态', value: 'capital', value2: 'time' },
        ],
        data: null
      },
      // 主要人员
      mainStaff: {
        label: [
          { label: '姓名', value: 'name' },
          { label: '职位', value: 'typeJoin' },
        ],
        data: this.epDetailsData.company_info.staffList.result || null
      }
    }
  },
  methods: {
    async getEpShareholders (page = 1) {
      await this.$parent.$parent.$parent.getEpShareholders(page);
      this.shareholders.data = this.epShareholders.items
      this.shareholders.pageTotal = this.epShareholders.total
      this.shareholders.page = page
    },
    async handleClick () {
      if (this.tabsName == 'equity') {
        this.getEpShareholders()
      }
    },
    // 分页切换
    changePage (page) {
      this.getEpShareholders(page)
    },
  },
}
</script>
<style lang="scss" scoped>
.assets {
  padding: 70px 0 60px;
  li {
    &:first-child {
      margin-right: 130px;
    }
    &:last-child {
      margin-left: 130px;
    }
  }
  .assets-img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
  }
  .assets-text {
    padding-top: 5px;
    margin-left: 10px;
    font-weight: bold;
    font-size: 20px;
    .assets-name {
      font-size: 18px;
    }
    .assets-num {
      font-size: 60px;
    }
  }
}

// 基本信息
::v-deep .el-descriptions {
  .el-descriptions-item__label {
    width: 14%;
  }
  .el-descriptions-item__content {
    width: 36%;
  }
}
</style>