/** 企业专利资产 */
<template>
  <div>
    <!-- 企业专利分布图 -->
    <div class="back-fff mb-10">
      <div class="content charts pb-50">
        <el-tabs v-model="linePieName" class="tabs-line line-pie ptb-32" @tab-click="handleTabs">
          <el-tab-pane label="专利申请数量分布" name="applyFor">
            <div v-show="dataLine.length" ref="applyFor" class="apply-for"></div>
            <el-empty v-show="!dataLine.length" class="apply-for" description="对不起，该公司暂无专利申请"></el-empty>
          </el-tab-pane>
          <el-tab-pane label="专利授权年度分布" name="authorization">
            <div v-show="dataLine.length" ref="authorization" class="apply-for"></div>
            <el-empty v-show="!dataLine.length" class="apply-for" description="对不起，该公司暂无专利授权"></el-empty>
          </el-tab-pane>
          <div v-show="dataLine.length" class="patent-num-all">
            <span class="patent-num">{{this.dataLineNumAll}}</span>
            <br />
            <span>{{yearText}}专利总量</span>
          </div>
        </el-tabs>
        <div class="pie-charts justify-around">
          <PieChart v-if="loading" title="专利法律状态分布" :data="patent_status" :legendShow="true" />
          <PieChart v-if="loading" title="国际申请分布" :data="patent_dist" :legendShow="true" />
        </div>
      </div>
    </div>

    <!-- 企业专利列表 -->
    <div class="back-fff pb-50">
      <EpAssetsList assetsType="patent" :assetsTaps="epPatentTabs" :assetsLegalStatus="epTradeLegalStatus"
        :data="epPatentData.data" :labelData="epPatentData.label" :page="filtersData.page" :total="pageTotal"
        :pageSize="10" :isMaxTotal="true" @changePage="changePage" @sortingFilters="sortingFilters">
      </EpAssetsList>
    </div>
  </div>
</template>
<script>
import PieChart from '../components/pieChart.vue'
import EpAssetsList from '../components/epAssetsList'
import AssetsTable from '@/components/Assets/table.vue'
import { getPatentList, getEpPatentChart } from '@/api/patent'
export default {
  props: ['company_info'],
  components: {
    PieChart,
    EpAssetsList,
    AssetsTable
  },
  data () {
    return {
      linePieName: 'applyFor',
      loading: false,
      apply_patentAll: 0,// 申请数量--总数量
      patent_authAll: 0,// 授权年度--总数量
      apply_patent: [],//专利申请数量--折线图
      patent_auth: [],//专利授权年度--折线图
      yearText: '',
      dataLine: [],
      dataLineNumAll: 0,
      patent_status: [
        { name: '有权', value: 0 },
        { name: '无权', value: 0 },
        { name: '再审', value: 0 }
      ],// 专利法律状态分布--饼图
      patent_dist: [// 专利国际申请分布--饼图
        { name: '中国大陆', value: 0 },
        { name: '国外', value: 0 }
      ],
      // 企业专利列表--tab筛选数据
      epPatentTabs: [
        { label: '专利资产', name: this.company_info.name, field: 'ASPO' },
        { label: '已许可专利', name: '是', field: 'license' },
        { label: '已质押专利', name: '1', field: 'pledge' },
        { label: '可质押专利', name: '2', field: 'pledge' }
      ],
      epTradeLegalStatus: [//企业商标列表--法律状态
        {
          value: '有权',
          label: '有权'
        },
        {
          value: '无权',
          label: '无权'
        },
        {
          value: '在审',
          label: '在审'
        },
        {
          value: '不确定或不影响',
          label: '不确定或不影响'
        },
      ],
      // 列表数据
      epPatentData: {
        label: [
          { label: '专利号', value: 'ano', width: '200px', isSlot: true },
          { label: '专利名称', value: 'tio', width: '300px' },
          { label: '专利类型', value: 'pdt', width: '115px' },
          { label: '法律状态', value: 'lsscn', width: '100px', },
          { label: '申请日', value: 'ad' },
          { label: '公开（公告）日', value: 'pd' },
          // { label: '授权日', value: 'pd' },
        ],
        data: null
      },
      pageTotal: 0,
      filtersData: { ASPO: this.company_info.name, sort: '-AD', page: 1 },
      isEchartsReset: false, //echarts图表重置
    }
  },

  mounted () {
    if (this.company_info.historyNameList && this.company_info.historyNameList.length) {
      this.epPatentTabs.splice(1, 0, { label: '企业曾用名专利', name: this.company_info.historyNameList, field: 'ASPO' })
    }
    this.getEpPatentChart()
    this.getEpPatentList()
  },
  methods: {
    // 企业专利列表
    async getEpPatentList () {
      if (!this.filtersData['ASPO']) this.filtersData['ASPO'] = this.company_info.name
      try {
        let { data, status } = await getPatentList(this.filtersData)
        if (status == 200) {
          this.epPatentData.data = data.data
          this.pageTotal = data.total *= 1
        } else {
          this.epPatentData.data = null
          this.pageTotal = 0
        }
      } catch (error) {
      }
    },
    // 图表数据
    async getEpPatentChart () {
      try {
        let { data, status } = await getEpPatentChart(this.company_info.name)
        this.loading = true
        if (status == 200) {
          // 专利申请数量分布--折线图
          this.setLineX(data.apply_patent.charts[0], this.apply_patent, 'ay', '专利申请数量分布')
          this.apply_patentAll = data.apply_patent.apply_count
          // 专利授权年度分布--折线图
          this.setLineX(data.patent_auth.auth_charts[0], this.patent_auth, 'py', '专利授权年度分布')
          this.patent_authAll = data.patent_auth.charts_count
          this.dataLine = [...this.apply_patent]
          this.$nextTick(() => {
            this.initEcharts(this.dataLine)
          })
          // 专利法律状态分布--饼图
          this.patent_status = data.patent_status.map((item) => {
            return { name: item.value, value: item.count }
          })
          // 专利国际申请分布--饼图
          if (data.patent_dist) {
            let All = data.patent_status.find(i => i.value == '有权').count
            this.patent_dist[0].value = data.patent_dist
            this.patent_dist[1].value = (All *= 1) - data.patent_dist
          }
        }
      } catch (error) {
        return false
      }
    },
    // 切换折线图数据
    handleTabs (tab) {
      this.dataLine = tab.name == 'applyFor' ? [...this.apply_patent] : [...this.patent_auth]
      //检测是否已经存在echarts实例，如果存在，则不再去初始化
      let myChart = this.$echarts.getInstanceByDom(
        this.$refs[this.linePieName]
      );
      if (myChart == null) {
        this.$nextTick(() => {
          this.initEcharts(this.dataLine)
        })
      }
    },
    // 折线图X轴
    setLineX (val, arr, Xfield, text) {
      arr[0] = arr[0] ? arr[0] : []
      for (const key in val) {
        val[key].forEach((item, index) => {
          if (!arr[0].find(i => i == item[Xfield])) {
            arr[0].push(item[Xfield])
          }
        })
      }
      arr[0] = arr[0].sort()
      arr[0].unshift(text)
      if (Object.keys(val).length < 3) {
        if (!val.发明) {
          val['发明'] = []
        }
        if (!val.外观设计) {
          val['外观设计'] = []
        }
        if (!val.实用新型) {
          val['实用新型'] = []
        }
      }
      this.setLineData(val, arr, Xfield)
    },
    // 折线图数据
    setLineData (val, arr, Xfield) {
      let ind = 0
      for (const key in val) {
        ind += 1
        arr[ind] = arr[ind] ? arr[ind] : []
        let keys = key == '发明' ? '发明专利' : key
        arr[ind].push(keys)
        arr[0].forEach((item, index) => {
          if (!index) { return false }
          let objItem = val[key].find(i => i[Xfield] == item)
          if (objItem) {
            let num = typeof objItem.value == 'string' ? objItem.value.replace(/,/g, '') : objItem.value
            arr[ind].push(num *= 1)
          } else {
            arr[ind].push(0)
          }
        })
      }
    },
    // 饼图数据--专利总数
    setDataLineAll () {
      this.dataLineNumAll = 0
      this.yearText = ''
      let oldArr = JSON.parse(JSON.stringify(this.dataLine))
      let newArr = []
      oldArr.forEach((item, index) => {
        if (!index) { return false }
        newArr.push({ name: item[0], value: 0 })
        item.shift()
        newArr[index - 1].value = item.reduce((prev, next) => {
          return prev + next
        })
      })
      this.dataLineNumAll = newArr.reduce((prev, next) => {
        return prev + next.value
      }, 0)
      return newArr
    },
    // 鼠标移入折线图，饼图数据更新
    updateDataLine (ind) {
      this.dataLineNumAll = 0
      let newArr = []
      this.dataLine.forEach((item, index) => {
        if (!index) { this.yearText = item[ind]; return false }
        newArr.push({ name: item[0], value: item[ind] })
        this.dataLineNumAll += item[ind]
      })
      return newArr
    },
    // 折线图
    initEcharts (data) {
      if (!data.length) { return false }
      let myChart = this.$echarts.init(this.$refs[this.linePieName])
      let option = {
        legend: {
          top: '7',
          right: '32',
          itemGap: 50, // 图例之间的间距
          textStyle: { //图例文字的样式
            fontSize: 14
          },
        },
        // graphic: { //图形中间文字
        //   type: "text",
        //   right: "15.5%",
        //   top: "center",
        //   style: {
        //     text: this.dataLineNumAll,
        //     textAlign: "center",
        //     fill: "#333",
        //     fontSize: 20
        //   }
        // },
        // 鼠标悬移提示内容
        tooltip: {
          trigger: 'axis',
        },
        grid: {
          top: '24%',
          left: '5%',
          right: '38%',
          bottom: '10%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisLabel: {
            show: true,
            color: '#000',
            fontSize: '16'
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#113d5e'
            }
          },
          axisLabel: {
            color: '#000',
            fontSize: '16'
          }
        },
        // 数据过多，设置滚动条
        dataZoom: [{
          type: 'slider',
          show: data[0].length > 8 ? true : false,
          start: data[0].length > 8 ? 100 - Math.floor(763 / (109 * 14) * 100) : 0,//默认为0  可设置滚动条从在后进行展示
          end: data[0].length > 8 ? 100 : 109 * data.length,//默认为100
          // xAxisIndex: [0],
          handleSize: 0,//滑动条的 左右2个滑动条的大小
          height: 12,//组件高度
          // left: '10%', //左边的距离
          // right: '10%',//右边的距离
          bottom: '3%',//右边的距离
          borderColor: "#D1D1D1",
          fillerColor: '#E7E7E7',// 选中的滑动条区域的颜色
          backgroundColor: '#D1D1D1',//两边未选中的滑动条区域的颜色
          showDataShadow: false,//是否显示数据阴影 默认auto
          // showDetail: false,//即拖拽时候是否显示详细数值信息 默认true
          realtime: true, //是否实时更新
          filterMode: 'filter',
          handleStyle: {
            borderRadius: '20',
          },
        }],
        // 数据集
        dataset: {
          source: data
        },
        series: [
          {
            type: 'line',
            seriesLayoutBy: 'row',
            symbol: 'circle', // 实心拐点
            symbolSize: 8, // 拐点大小
            // emphasis: { focus: 'series' }
          },
          {
            type: 'line',
            seriesLayoutBy: 'row',
            symbol: 'circle',
            symbolSize: 8,
            // emphasis: { focus: 'series' }
          },
          {
            type: 'line',
            seriesLayoutBy: 'row',
            symbol: 'circle',
            symbolSize: 8,
            // emphasis: { focus: 'series' }
          },
          {
            type: 'pie',
            id: 'pie',
            radius: ['30%', '55%'],
            center: ['82%', '55%'],
            title: {
              text: "80%",
              left: "center",
              top: "50%",
              textStyle: {
                color: "#27D9C8",
                fontSize: 36,
                align: "center"
              }
            },

            tooltip: {
              trigger: 'item'
            },
            label: {
              formatter: '{d}%',
              fontSize: "16",
            },
            // emphasis: { focus: 'self' },
            data: this.setDataLineAll(),
          }
        ]
      };
      myChart.on('updateAxisPointer', (event) => {
        const xAxisInfo = event.axesInfo[0];
        if (xAxisInfo) {
          this.isEchartsReset = true
          const dimension = xAxisInfo.value + 1;
          myChart.setOption({
            // graphic: { //图形中间文字
            //   style: {
            //     text: this.dataLineNumAll,
            //   }
            // },
            series: {
              id: 'pie',
              data: this.updateDataLine(dimension),
            }
          });
        } else {
          if (this.isEchartsReset) {
            myChart.setOption({
              // graphic: { //图形中间文字
              //   style: {
              //     text: this.dataLineNumAll,
              //   }
              // },
              series: {
                id: 'pie',
                data: this.setDataLineAll(),
              }
            });
            this.isEchartsReset = false
          }
        }
      });
      myChart.setOption(option);
    },
    // 筛选
    sortingFilters (val) {
      this.filtersData = val
      this.getEpPatentList()
    },
    // 分页切换
    changePage (page) {
      this.filtersData.page = page
      this.getEpPatentList()
    }
  },

}
</script>
<style lang="scss" scoped>
.apply-for {
  width: 100%;
  height: 398px;
}
.patent-num-all {
  width: 118px;
  height: 118px;
  background-color: #ecf2ff;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  top: 160px;
  right: 12.6%;
  span {
    width: 100%;
  }
  .patent-num {
    display: inline-block;
    margin: 30px auto 10px;
    font-size: 18px;
  }
}
</style>