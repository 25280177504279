/** 企业商标资产 */
<template>
  <div>
    <!-- 企业商标分布图 -->
    <div class="back-fff mb-10">
      <div class="content charts pb-50">
        <el-tabs v-model="linePieName" class="tabs-line line-pie default">
          <el-tab-pane label="商标申请趋势分布" name="applyFor">
            <div class="flex trademark-nums">
              <p> 商标申请量： <span class="trademark-num">{{dataLineFyNum}} </span> 件 </p>
              <p> 商标注册量： <span class="trademark-num">{{dataLineRyNum}} </span> 件 </p>
            </div>
            <div v-show="dataLine[0].length>1" ref="applyFor" class="apply-for"></div>
            <el-empty v-show="dataLine[0].length<=1" class="apply-for" description="对不起，该公司暂无商标申请">
            </el-empty>
          </el-tab-pane>
        </el-tabs>
        <div class="pie-charts justify-around">
          <PieChart v-if="loading" title="商标国际分类分布" :data="reg_nc" />
          <PieChart v-if="loading" title="商标法律状态分布" :data="cs" />
        </div>
      </div>
    </div>

    <!-- 企业商标列表 -->
    <div class="back-fff pb-50">
      <EpAssetsList assetsType="trademark" :tradeNc="tradeNc" :assetsTaps="epTradeTabs"
        :assetsLegalStatus="epTradeLegalStatus" :data="epTrademarkData.data" :labelData="epTrademarkData.label"
        :page="filtersData.page" :total="pageTotal" :pageSize="10" :isMaxTotal="true" @changePage="changePage"
        @sortingFilters="sortingFilters">
      </EpAssetsList>
    </div>
  </div>
</template>
<script>
import PieChart from '../components/pieChart.vue'
import EpAssetsList from '../components/epAssetsList'
import AssetsTable from '@/components/Assets/table.vue'
import { getTradeList, getEpTradeChart } from '@/api/trademark'
export default {
  props: ['company_info'],
  components: {
    PieChart,
    EpAssetsList,
    AssetsTable
  },
  data () {
    return {
      linePieName: 'applyFor',
      loading: false,
      dataLineInd: 1,
      dataLine: [[], [], []], // 折线图数据
      dataLineFyNum: 0, // 商标申请量
      dataLineRyNum: 0, //商标注册量
      reg_nc: [],// 商标国际分类分布--饼图数据
      cs: [ // 商标法律状态分布--饼图数据
        { name: '已注册', value: 0 },
        { name: '待审中', value: 0 },
        { name: '已初审', value: 0 },
        { name: '已销亡', value: 0 },
        { name: '已驳回', value: 0 },
      ],
      // 企业商标列表--tab筛选数据
      epTradeTabs: [
        { label: '商标资产', name: this.company_info.name, field: 'HNO' },
        { label: '已质押商标', name: '1', field: 'pledge' },
        { label: '可质押商标', name: '2', field: 'pledge' }
      ],
      tradeNc: [],//企业商标列表--商标国际分类
      epTradeLegalStatus: [//企业商标列表--法律状态
        {
          value: '待审中',
          label: '待审中'
        },
        {
          value: '已初审',
          label: '已初审'
        },
        {
          value: '已注册',
          label: '已注册'
        },
        {
          value: '已驳回',
          label: '已驳回'
        },
        {
          value: '已销亡',
          label: '已销亡'
        }
      ],
      // 列表数据
      epTrademarkData: {
        label: [
          { label: '注册号', value: 'rn', isSlot: true },
          { label: '商标名称', value: 'mno' },
          { label: '国际分类', value: 'nc_cn' },
          { label: '法律状态', value: 'cs' },
          { label: '申请日', value: 'fd' },
          { label: '申请人', value: 'hno' },
          { label: '代理机构', value: 'aro' },
        ],
        data: null
      },
      pageTotal: 0,
      filtersData: { HNO: this.company_info.name, sort: '-FD', page: 1 }
    }
  },
  mounted () {
    if (this.company_info.historyNameList && this.company_info.historyNameList.length) {
      this.epTradeTabs.splice(1, 0, { label: '企业曾用名商标', name: this.company_info.historyNameList, field: 'HNO' })
    }
    this.getEpTradeChart()
    this.getEpTradeList()
  },
  methods: {
    // 企业商标列表
    async getEpTradeList () {
      if (!this.filtersData['HNO']) this.filtersData['HNO'] = this.company_info.name
      try {
        let { data, status } = await getTradeList(this.filtersData)
        if (status == 200) {
          this.epTrademarkData.data = data.data
          this.pageTotal = data.total *= 1
        } else {
          this.epTrademarkData.data = null
          this.pageTotal = 0
        }
      } catch (error) {

      }
    },
    // 图表数据
    async getEpTradeChart () {
      try {
        let { data } = await getEpTradeChart(this.company_info.name)
        this.loading = true
        this.setLineX(data.fy || [], data.ry || []) // 折线图X轴
        this.setLineData(data.fy || [], data.ry || []) // 折线图数据
        this.$nextTick(() => {
          this.initEcharts(this.dataLine) // 折线图显示
        })
        this.tradeNc = data.nc.sort(this.$utils.compare('value', '+'))//排序-正序

        /** 
         * 商标国际分类分布--饼图
         * reduce 计算总数量
         * forEach计算每项所占百分比，百分比>3展示，反之，归为其他
         */
        let allNum = data.reg_nc.reduce((prev, cur) => {
          return prev + (cur.count *= 1)
        }, 0)
        data.reg_nc.forEach(item => {
          item.count = item.count *= 1
          if ((item.count / allNum * 100) > 3) {
            this.reg_nc.push({ name: item.nc_cn, value: item.count })
          } else {
            let orther = this.reg_nc.findIndex(i => i.name == '其他')
            if (orther != -1) {
              this.reg_nc[orther].value += item.count
            } else {
              this.reg_nc.push({ name: '其他', value: item.count })
            }
          }
        })
        // 商标法律状态分布--饼图
        this.cs.forEach(item => {
          let csItem = data.cs.find((i) => i.value == item.name)
          if (csItem) {
            item.value = csItem.count
          }
        });
      } catch (error) {

      }
    },

    // 折线图X轴
    setLineX (arr1, arr2) {
      // arr1 = arr1.sort((a, b) => a['value'] > b['value'] ? 1 : -1)
      this.dataLine[0] = this.dataLine[0] ? this.dataLine[0] : []
      arr1.forEach(item => {
        item.value = item.value.substring(0, 4)
        if (!this.dataLine[0].find(i => i == item.value)) {
          this.dataLine[0] = [...this.dataLine[0], item.value]
        }
      })
      this.dataLine[0] = this.dataLine[0].sort()
      if (arr2) {
        return this.setLineX(arr2)
      }
    },
    // 折线图数据
    setLineData (arr1, arr2) {
      this.dataLine[this.dataLineInd] = this.dataLine[this.dataLineInd] ? this.dataLine[this.dataLineInd] : []
      this.dataLine[0].forEach(item => {
        let itemObj = arr1.find(i => i.value == item)
        if (itemObj) {
          itemObj.count *= 1
          this.dataLine[this.dataLineInd].push(itemObj.count)
          this.dataLineInd == 1 ? this.dataLineFyNum += itemObj.count : this.dataLineRyNum += itemObj.count
        } else {
          this.dataLine[this.dataLineInd].push(0)
        }
      })
      if (arr2) {
        this.dataLineInd += 1
        return this.setLineData(arr2)
      }
      this.dataLine[0].unshift('商标申请趋势分布')
      this.dataLine[1].unshift('商标申请量')
      this.dataLine[2].unshift('商标授权量')
    },
    // 折线图
    initEcharts (data) {
      let myChart = this.$echarts.init(this.$refs[this.linePieName])
      let option = {
        legend: {
          top: '7',
          right: '32',
          itemGap: 50, // 图例之间的间距
          textStyle: { //图例文字的样式
            fontSize: 14
          },
        },
        tooltip: {
          trigger: 'axis',
        },
        dataset: {
          source: data
        },
        grid: {
          top: '24%',
          left: '5%',
          bottom: '10%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisLabel: {
            show: true,
            color: '#000',
            fontSize: '16'
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#113d5e'
            }
          },
          axisLabel: {
            color: '#000',
            fontSize: '16'
          }
        },
        // 数据过多，设置滚动条
        dataZoom: [{
          type: 'slider',
          show: data[0].length > 14 ? true : false,
          start: data[0].length > 14 ? 100 - Math.floor(1162 / (166 * 14) * 100) : 0,//默认为0  可设置滚动条从在后进行展示
          end: data[0].length > 14 ? 100 : 166 * data.length,//默认为100
          // xAxisIndex: [0],
          handleSize: 0,//滑动条的 左右2个滑动条的大小
          height: 12,//组件高度
          // left: '10%', //左边的距离
          // right: '10%',//右边的距离
          bottom: '3%',//右边的距离
          borderColor: "#D1D1D1",
          fillerColor: '#E7E7E7',// 选中的滑动条区域的颜色
          backgroundColor: '#D1D1D1',//两边未选中的滑动条区域的颜色
          showDataShadow: false,//是否显示数据阴影 默认auto
          // showDetail: false,//即拖拽时候是否显示详细数值信息 默认true
          realtime: true, //是否实时更新
          filterMode: 'filter',
          handleStyle: {
            borderRadius: '20',
          },
        }],
        series: [
          {
            type: 'line',
            seriesLayoutBy: 'row',
            symbol: 'circle',
            symbolSize: 8,
            // emphasis: { focus: 'series' }
          },
          {
            type: 'line',
            seriesLayoutBy: 'row',
            symbol: 'circle',
            symbolSize: 8,
            // emphasis: { focus: 'series' }
          },
        ]
      };
      myChart.setOption(option);
    },

    // 筛选
    sortingFilters (val) {
      this.filtersData = val
      this.getEpTradeList()
    },
    // 分页切换
    changePage (page) {
      this.filtersData.page = page
      this.getEpTradeList()
    }
  },
}
</script>
<style lang="scss" scoped>
.apply-for {
  width: 100%;
  height: 398px;
}
.trademark-nums {
  position: absolute;
  top: 3px;
  margin-left: 50%;
  transform: translateX(-50%);
  p {
    margin: 0 20px;
    span {
      font-size: 24px;
      font-weight: bold;
    }
  }
}
</style>